// color variants
@import "themes-vars.module.scss";

// third-party
@import "~react-perfect-scrollbar/dist/css/styles.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
* {
  font-family: "Urbanist", sans-serif !important;
}
body {
  background-color: transparent !important;
  
  
}
// ==============================|| LIGHT BOX ||============================== //
.slick-arrow:before {
  color: $grey500;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
  z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
  .ps__rail-y {
    &:hover > .ps__thumb-y,
    &:focus > .ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: $grey500;
      width: 5px;
    }
  }
  .ps__thumb-y {
    background-color: $grey500;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
  &.ps--active-y > .ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;
    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }
  &.ps--scrolling-y > .ps__rail-y,
  &.ps--scrolling-x > .ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

.searchable__list,
.searchable * {
  background-color: #fafafa !important;
  color: black !important;
  border-radius: 9px !important;
}
.searchable {
  border-radius: 9px !important;
  height: 50px !important;
}
.searchable--active {
  box-shadow: none !important;
}
.searchable__controls__input {
  height: 50px !important;
}
.searchable__controls__input > input {
  height: 50px !important;
}
.searchable__controls__input input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color:#988e8e !important;
  font-size: 14px;
  opacity: 1; /* Firefox */
}

.searchable__controls__input input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b1b1b1 !important;
  font-size: 14px;
}

.searchable__controls__input input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b1b1b1 !important;
  font-size: 14px;
}

.searchable__controls__arrow svg {
  fill: #b1b1b1 !important;
}
.searchable--active .searchable__list,
.searchable--active .searchable__controls {
  transition: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5) !important;
}
.required-title:after {
  content: "*";
  color: red;
  position: absolute;
  top: 16px;
  left: 39px;
}
.required-arabic-title:after {
  content: "*";
  color: red;
  position: absolute;
  top: 16px;
  left: 45px;
}
.required:after {
  content: "*";
  color: red;
  position: absolute;
  top: 16px;
  left: 79px;
}
.arabic-required:after{
  content: "*";
  color: red;
  position: absolute;
  top: 16px;
  left: 119px;
}
.required-password:after {
  content: "*";
  color: red;
  position: absolute;
  top: 16px;
  left: 97px;
}
.required-confirm-password:after {
  content: "*";
  color: red;
  position: absolute;
  top: 16px;
  left: 149px;
}
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}
.ltr-class>div>div>div>div >input{
    direction:ltr
  }
  // .ltr-class>div .searchable__list{
  //   direction:ltr
  // }

.rtl-class >div>div>div>div >input{
    direction:rtl
  }
  // .rtl-class>div .searchable__list{
  //   direction:rtl 
  // }


.Instructor-slider >div{
  padding-left:12px !important
}
#tooltip{
  position: absolute;
  // margin-top: 5px;
  width: 80px;
  background-color: #8C92AC;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  right: -15px;
    top: -30px;
}
.ps--active-x > .ps__rail-x {
  display:none !important
}
.ps__thumb-x{
  display:none !important
}



@media screen  and (max-width:500px) {
  .Instructor-slider{
    padding-left:27px !important;
    padding-right:14px !important
  }
}
// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {
  0%,
  50%,
  100% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(10px);
  }
}

@keyframes slideX {
  0%,
  50%,
  100% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
}
